import Image from "next/image";
import Link from "next/link";

const Footer = () => {
  return (
    <footer className="bg-[#0B1326] px-[13px]">
      <div className="mx-auto max-w-7xl">
        <div className="lg:flex flex-row lg:space-x-0 space-x-2 items-stretch justify-center gap-[16px] lg:gap-10 text-base text-[#C5CFE8] pt-[132px]">
          <Link href="/about">
            <p className=" lg:pr-[40px] lg:border-r border-[#7385AB] h-full text-center">About</p>
          </Link>
          <Link href={"/privacy-policy"}>
            <p className=" lg:pr-[40px] lg:border-r border-[#7385AB] h-full text-center">
              Privacy Policy
            </p>
          </Link>
          <Link href="/terms-and-conditions">
            <p className=" lg:pr-[40px] lg:border-r border-[#7385AB] h-full text-center">
              Terms of Use
            </p>
          </Link>
          <Link href="/cookie-policy">
            <p className="h-full text-center">Cookie policy</p>
          </Link>
        </div>
        {/* <p className='font-light text-sm text-[#7385AB] text-center max-w-[1229px] mx-auto pt-[31px]'>
					Lorem ipsum dolor sit amet consectetur. Praesent lobortis eu
					nibh in at eu. Imperdiet dictum gravida feugiat ipsum
					accumsan. Dolor magna consectetur facilisis amet eget
					lobortis est. Vitae ante cum venenatis congue massa mauris
					maecenas integer.˙
				</p> */}
        <div className="pt-[60px] flex flex-col lg:flex-row items-center justify-center gap-y-[14px] gap-x-[42px] lg:gap-y-0 border-b border-[#192950] pb-[30px]">
          <div className="flex items-center justify-center gap-[12.5px] lg:gap-[20px]">
            <Link href="/">
              <div className="text-[#C5CFE8] flex items-center justify-center gap-2">
                <p className="text-base font-bold">Rent80</p>
              </div>
            </Link>
            <div className="flex items-center justify-center p-[6px] lg:p-[10px] rounded-full bg-[#101D39] w-fit gap-3 lg:gap-[19px] ">
              <Link href="https://instagram.com/rent80rentals" target="_blank">
                <div className="h-[20px] w-[20px] lg:h-[32px] lg:w-[32px]">
                  <Image height={32} width={32} src="/footer/instagram.svg" alt="Instagram logo" />
                </div>
              </Link>
              <Link href="https://www.facebook.com/rent80rentals/" target="_blank">
                <div className="h-[20px] w-[20px] lg:h-[32px] lg:w-[32px]">
                  <Image height={32} width={32} src="/footer/facebook.svg" alt="Facebook logo" />
                </div>
              </Link>
              <Link href="https://twitter.com/rent80rentals" target="_blank">
                <div className="h-[20px] w-[20px] lg:h-[32px] lg:w-[32px]">
                  <Image height={32} width={32} src="/footer/twitter.svg" alt="Twitter logo" />
                </div>
              </Link>
              {/* <Link
                href=""
                onClick={(e) => e.preventDefault()}
              >
              <Image
                src="/footer/youtube.svg"
                alt="Youtube logo"
                className="h-[20px] w-[20px] lg:h-[32px] lg:w-[32px]"
              />
              </Link> */}
              {/* <Link
                href="https://www.linkedin.com/company/rent80"
                target="_blank"
              >
              <Image
                src="/footer/linkedin.svg"
                alt="Linkedin logo"
                className="h-[20px] w-[20px] lg:h-[32px] lg:w-[32px]"
              />
              </Link> */}
            </div>
          </div>
          <div className="flex items-center justify-center gap-[14px]">
            {/* <Image
							height={41}
							width={144}
							src='/footer/app-store.svg'
							alt='Apple store logo'
							className='hidden lg:block'
						/>
						<Image
							height={26}
							width={90}
							src='/footer/app-store.svg'
							alt='Apple store logo'
							className='block lg:hidden'
						/> */}
            <a
              href={"https://play.google.com/store/apps/details?id=com.rent80.rentalcars"}
              target={"_blank"}
              rel="noreferrer noopener"
            >
              <div className="h-[41px] w-[144px]">
                <img
                  loading="lazy"
                  height={41}
                  width={144}
                  src="/footer/google-play.svg"
                  alt="Google play logo"
                  className="w-auto h-auto"
                />
              </div>
            </a>
            <a
              href="https://apps.apple.com/in/app/rent-a-car-rent80/id6472572506"
              target={"_blank"}
              rel="noreferrer noopener"
            >
              <div className="h-[41px] w-[144px]">
                <img
                  loading="lazy"
                  height={41}
                  width={144}
                  src="/footer/app-store.svg"
                  alt="Google play logo"
                  className="w-auto h-auto"
                />
              </div>
            </a>
          </div>
        </div>
        <Link href={"/"}>
          <Image
            height={40}
            width={144}
            src="/footer/logo-white.svg"
            alt="White logo of Rent80"
            className="mx-auto mt-[60px]"
          />
        </Link>
        <p className="mt-[6px] font-light text-sm text-center text-[#7385AB] pb-16">
          {`© ${new Date().getFullYear()} Rent80 | All right reserved`}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
