//FIXME: Make sure this is in sync with next.config.js, else it might break a few things

export const locales = [
  {
    code: "en-US",
    name: "United States",
    currency: "USD",
    currencySymbol: "$",
    cc: "US",
    ccMin: "us",
    currencyName: "US Dollar",
  },
];

export const findLocale = (code: string) => {
  return locales.find((locale) => locale.code === code) || locales[0];
};
