import Image from "next/image";
import classNames from "classnames";

const RentalPartners = ({
  type,
  agencyData,
  locationName,
}: {
  type?: string;
  agencyData?: {
    agency: Agency;
    provider: Provider;
  }[];
  locationName?: string;
}) => {
  const agencyList = agencyData?.filter(
    (agency) => agency.agency.name !== "Surprise Agency"
  );
  if (type === "agency") {
    return (
      <div className="w-full bg-white">
        <div className="mx-auto max-w-7xl pb-[20px] mt-[20px]">
          <div
            className={classNames(
              "flex flex-col items-center justify-center w-full px-2"
            )}
          >
            <h2
              className={classNames(
                "font-medium text-[15px] text-my-dark-blue text-center mb-[20px] mt-[22px]"
              )}
            >{`Our Car Rental partners at ${locationName}`}</h2>
          </div>

          <div className="flex flex-wrap items-center justify-center lg:justify-center justify-items-center gap-x-[14px] lg:gap-x-[24px] gap-y-[23px] lg:space-x-4 mx-auto w-full">
            {agencyList?.map((data, index) => (
              
              <div className="flex items-center justify-center p-2" key={index} onClick={()=>{
                document.getElementById("search_button")?.click()
              }}>
                <img
                  className="min-w-[60px] max-h-[30px]"
                  src={`https://content.r9cdn.net/rimg/provider-logos/cars/h/${data.agency.icon}`}
                  alt={data.agency.name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center w-full px-2",
        { " bg-white p-[20px]": type === "lp2" }
      )}
    >
      <h2
        className={classNames(
          "font-medium text-[15px] text-my-dark-blue text-center mb-[20px]",
          type !== "lp2" && "mt-[22px]"
        )}
      >
        Our Proud Rental Car Partners
      </h2>
      <div className="grid grid-cols-5 lg:flex lg:max-w-7xl items-center justify-center lg:justify-between justify-items-center gap-x-[14px] gap-y-[23px] lg:space-x-4 mx-auto w-full" onClick={()=>{
                document.getElementById("search_button")?.click()
              }}>
        <Image
          src="/hero/sixt.svg"
          width={60}
          height={30}
          alt="Sixt logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/dollar.svg"
          width={60}
          height={30}
          alt="Dollar logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/budget.svg"
          width={60}
          height={30}
          alt="Budget logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/national.svg"
          width={60}
          height={30}
          alt="National logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/enterprise.svg"
          width={60}
          height={30}
          alt="Enterprise logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/avis.svg"
          width={60}
          height={30}
          alt="Avis logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/hertz.svg"
          width={60}
          height={30}
          alt="Hertz logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/ace.svg"
          width={60}
          height={30}
          alt="Ace logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/alamo.svg"
          width={60}
          height={30}
          alt="Alamo logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
        <Image
          src="/hero/europcar.svg"
          width={60}
          height={30}
          alt="Europcar logo"
          className="w-full max-w-[60px] md:max-w-[100px] lg:max-w-full"
        />
      </div>
    </div>
  );
};

export default RentalPartners;
